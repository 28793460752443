/* eslint-disable prettier/prettier */
import React from 'react';
import WalletTable from 'components/wallet-table';
import { Layout } from '@components';
import Card from '@containers/screens/card';
import { getDateString } from '@utils';
import {
  Header,
  CardWrapper,
  MainWrapper,
  Wrapper,
  CardContainer,
  CardHeader,
  InnerCard,
  CardBody,
  ColumnWrapper,
  RowWrapper,
  TextItems,
  TableWrapper,
} from './style';
import useHooks from './useHooks';

const Wallet = ({ setPage, page }) => {
  const { getTransactions, allTransactions } = useHooks();

  const [allTableData, setAllTableData] = React.useState([]);

  React.useEffect(() => {
    getTransactions();
  }, []);

  React.useEffect(() => {
    let allData = [];
    if (allTransactions.length > 0) {
      allTransactions[0]?.donation.map((element, index) => {
        allData.push({
          type: 'Donation',
          date: getDateString(element?.created_at),
          room: element?.room?.title,
          system: element?.system?.title,
          amount: element?.amount,
          currency: element?.crypto_coin_name,
          payment_method: '-',
          status: element?.transaction_status,
        });
      });

      allTransactions[0]?.redeem.map((element, index) =>
        allData.push({
          type: 'Redeem',
          date: getDateString(element?.created_at),
          room: element?.room?.title,
          system: element?.system?.title,
          amount: element?.redeem_amount,
          currency: '-',
          payment_method: element?.payment_method,
          status: element?.status,
        })
      );
      setAllTableData(allData);
    }
  }, [allTransactions]);

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: {
        compare: (a, b) => a.type.localeCompare(b.type),
      },
      multiple: 3,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      sorter: {
        compare: (a, b) => a.date.localeCompare(b.date),
      },
      multiple: 3,
    },
    {
      title: 'Room',
      dataIndex: 'room',
      sorter: {
        compare: (a, b) => a.room.localeCompare(b.room),
      },
      multiple: 2,
    },
    {
      title: 'System',
      dataIndex: 'system',
      sorter: {
        compare: (a, b) => a.system.localeCompare(b.system),
      },
      multiple: 1,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: {
        compare: (a, b) => a.amount - b.amount,
      },
      multiple: 1,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      sorter: {
        compare: (a, b) => a.currency.localeCompare(b.currency),
      },
      multiple: 1,
    },
    {
      title: 'Payment Method',
      dataIndex: 'payment_method',
      sorter: {
        compare: (a, b) => a.payment_method.localeCompare(b.payment_method),
      },
      multiple: 1,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
      multiple: 1,
    },
  ];

  if (!allTransactions) return <></>;

  return (
    <Layout setPage={setPage} page={page}>
      <MainWrapper>
        <Header>WALLET</Header>
        <Wrapper>
          <CardWrapper>
            <CardContainer>
              <Card>
                <InnerCard>
                  <WalletTable columns={columns} tableData={allTableData} />
                </InnerCard>
              </Card>
            </CardContainer>
          </CardWrapper>
        </Wrapper>
      </MainWrapper>
    </Layout>
  );
};

export default Wallet;
