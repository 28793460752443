/* eslint-disable prettier/prettier */
import React from 'react';
import { useState } from 'react';
import { emailValidator, passwordValidator, textValidator, requiredValidator } from 'utils/validators';
import { Card, Notification, DropDown, Spinner } from '@components';
import { VisionSvg, EyeSvg } from '@svgs';
import useHooks from '../useHooks';
import {
  Wrapper,
  InnerWrapper,
  ItemField,
  Button,
  InstructionContainer,
  SignupContainer,
  TermsContainer,
  AgeContainer,
  ItemFieldError,
} from './styles';

const Signup = ({ setPage }) => {
  const { signup, allArea, getAllArea, getCity, allCities } = useHooks();
  const [userInfoError, setUserInfoError] = useState({});
  const [alert, setAlert] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userInfo, setUserInfo] = useState({ age_check: 'true', 'country': '' });
  const [userArea, setUserArea] = useState([]);
  const [userCity, setUserCity] = useState([]);
  const [selectedAreaImg, setSelectedAreaImg] = useState("https://slyod-designs-37273.s3.amazonaws.com/media/area_images/default.jpg");
  const [imageLoading, setImageLoading] = useState(true);
  const validation = () => {
    const errors = {};
    let validation = true;
    if (textValidator(userInfo.first_name)) {
      errors.first_name = textValidator(userInfo.first_name, 'First Name');
      validation = false;
    }

    if (textValidator(userInfo.last_name)) {
      errors.last_name = textValidator(userInfo.last_name, 'Last Name');
      validation = false;
    }

    if (requiredValidator(userInfo.area)) {
      errors.area = requiredValidator(userInfo.area, 'Area')
      validation = false;
    }

    if (requiredValidator(userInfo.city)) {
      errors.city = requiredValidator(userInfo.city, 'City')
      validation = false;
    }
  
    if (emailValidator(userInfo.email)) {
      errors.email = emailValidator(userInfo.email);
      validation = false;
    }

    if (passwordValidator(userInfo.password)) {
      errors.password = passwordValidator(userInfo.password);
      validation = false;
    }

    if (userInfo.password !== userInfo.confirm_password) {
      errors.confirm_password = 'Confirm password does not match with New password.';
      validation = false;
    }
    if (!validation) {
      setUserInfoError(errors);
    }

    return validation;
  };

  const areaImgLoaded = () => {
    setImageLoading(false);
  }
  const onDropdownSelect = (name, item) => {
    setUserInfo({ ...userInfo, [name]: item.value });
    if(userInfoError[name]){
      setUserInfoError({...userInfoError, [name]: ''});
    }
    if(item.image){
      setSelectedAreaImg(item.image);
      setImageLoading(true);
    }
  };

  React.useEffect(() => {
    getAllArea();
  }, []);

  React.useEffect(() => {
    if (allArea) {
      setUserArea(
        allArea.map((item) => ({
          title: item.title,
          value: item.id,
          id: `${item.id}`,
          image: item.image
        }))
      );
    }
  }, [allArea]);

  React.useEffect(() => {
    if (allCities) {
      setUserCity(
        allCities.map((item) => ({
          title: item,
          value: item,
        }))
      );
    }
  }, [allCities]);

  React.useEffect(() => {
    if (userInfo.area) {
      getCity(userInfo.area);
    }
  }, [userInfo.area]);

  const signUp = async () => {
    if (validation()) {
      debugger
      let formData = new FormData();
      for (const [key, value] of Object.entries(userInfo)) {
        if (key === 'age_check') {
          formData.append(key, value === 'true' ? true : false);
        } else {
          formData.append(key, value);
        }
      }
      const res = await signup(formData);
      try {
        if (res.success) {
          setPage({
            id: 'login',
            alert: {
              title: 'Account created successfully.',
              text: 'Your account is under review, and you will be notified once the review is completed.',
              success: true,
            },
          });
        } else if (res?.non_field_errors) {
          setAlert({
            title: 'Signup Error!',
            text: JSON.stringify(res?.non_field_errors[0]),
          });
        } else {
          setUserInfoError(res);
        }
      } catch (error) {
        setAlert({
          title: 'Signup Error!',
          text: JSON.stringify(error),
        });
      }
    }
  };

  const onTextChange = (event) => {
    const { name, value } = event.target;
    if (userInfoError[name]) {
      setUserInfoError({ ...userInfoError, [name]: '' });
    }
    setUserInfo({ ...userInfo, [name]: value });
  };

  const FIELDS = [
    {
      title: 'First Name',
      name: 'first_name',
      type: 'input',
      placeholder: 'First Name',
      onChange: onTextChange,
    },
    {
      title: 'Last Name',
      name: 'last_name',
      type: 'input',
      placeholder: 'Last Name',
      onChange: onTextChange,
    },
    {
      title: 'Email',
      name: 'email',
      type: 'input',
      placeholder: 'Email',
      onChange: onTextChange,
    },
    {
      title: 'Create Password',
      name: 'password',
      placeholder: 'Create Password',
      icon: showPassword ? EyeSvg : VisionSvg,
      type: showPassword ? 'text' : 'password',
      iconClick: () => setShowPassword(!showPassword),
      onChange: onTextChange,
    },
    {
      title: 'Confirm Password',
      name: 'confirm_password',
      placeholder: 'Confirm Password',
      type: showConfirmPassword ? 'text' : 'password',
      icon: showConfirmPassword ? EyeSvg : VisionSvg,
      iconClick: () => setShowConfirmPassword(!showConfirmPassword),
      onChange: onTextChange,
    },
  ];

  return (
    <Wrapper>
      <Card title={'SIGN UP'}>
        <InnerWrapper>
          <Notification Notification={alert} />
          {FIELDS.map((item, index) => {
            const Icon = item.icon;
            const { iconClick } = item;
            return (
              <div key={index}>
                {item.name === 'password' && (
                  <AgeContainer>
                    <span>Are You Older Than 18</span>
                    <div className="radio_div">
                      <div className="radio_options">
                        <input
                          type="radio"
                          name="age_check"
                          value={'true'}
                          onChange={onTextChange}
                          checked={userInfo.age_check === 'true'}
                        />
                        <label>Yes</label>
                      </div>
                      <div className="radio_options">
                        <input
                          type="radio"
                          name="age_check"
                          value={'false'}
                          onChange={onTextChange}
                          checked={userInfo.age_check === 'false'}
                        />
                        <label>No</label>
                      </div>
                    </div>
                  </AgeContainer>
                )}
                <ItemField key={index} error={userInfoError[item.name]}>
                  <div className="input-row">
                    {(!item.type ||
                      item.type === 'input' ||
                      item.type === 'password' ||
                      item.type === 'text') && <input {...item} />}

                    {Icon && <Icon onClick={iconClick} />}
                  </div>
                  {userInfoError[item.name] && (
                    <ItemFieldError>{userInfoError[item.name]}</ItemFieldError>
                  )}
                </ItemField>
              </div>
            );
          })}
          <InstructionContainer>
            Password must be at least 8 alphanumeric, with at least one special character and one
            number.
            <DropDown
              width="100%"
              valueKey="value"
              onChange={(e) => onDropdownSelect("area", e)}
              getLabel={(o) => o.title}
              style={{ marginTop: 5, borderBottom: '1px solid grey' }}
              title="Area"
              name="area"
              placeholder="Area"
              type="dropdown"
              value={userInfo.area}
              options={userArea}
            />
            {userInfoError['area'] && (
              <ItemFieldError>{userInfoError['area']}</ItemFieldError>
            )}
            {imageLoading && <Spinner size="small"/>}
            <img
              src={selectedAreaImg}
              style={{ height: '274px', width: '100%', margin: '20px 0 20px 0' }}
              alt={'alt'}
              onLoad={areaImgLoaded}
            />
            <span>Observe the Map to choose Area that best suite your residence</span>
            <DropDown
              width="100%"
              valueKey="value"
              onChange={(e) => onDropdownSelect("city", e)}
              getLabel={(o) => o.title}
              style={{ marginTop: 5, borderBottom: '1px solid grey', margin: '25px 0 20px 0' }}
              title="City"
              name="city"
              placeholder="City"
              type="dropdown"
              value={userInfo.city}
              options={userCity}
            />
            {userInfoError['city'] && (
              <ItemFieldError>{userInfoError['city']}</ItemFieldError>
            )}
            <span>If the city you live in is not on the list choose the city closest to you</span>
          </InstructionContainer>

          <Button onClick={signUp}>Create an Account</Button>

          <TermsContainer>
            <span>By using Slyod Designs you agree to our</span>
            <span className="highlight" onClick={() => setPage('terms-and-conditions')}>
              Terms and Conditions
            </span>
            <div>and</div>
            <span className="highlight" onClick={() => setPage('privacy-policy')}>
              Privacy Notice
            </span>
          </TermsContainer>
          <SignupContainer>
            Already have an account?
            <span className="login" onClick={() => setPage('login')}>
              Login
            </span>
          </SignupContainer>
        </InnerWrapper>
      </Card>
    </Wrapper>
  );
};

export default Signup;
